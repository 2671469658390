import { ObjectHelper } from '~/helpers/ObjectHelper';
import type { Schemas } from '#shopware';
import { ApiClientError } from '@shopware/api-client';

export const useAvailability = () => {
    const { apiClient } = useShopwareContext();
    const { isLoggedIn } = useUser();

    const availabilities = useState<Record<string, Schemas['AhProductAvailability'][]>>('availabilities', () =>
        ref({}),
    );
    const _loadedProductNumbers = computed(() => Object.keys(availabilities.value));

    const _cleanProductNumbers = (productNumbers: string[]): string[] => {
        return productNumbers.filter((productNumber) => !_loadedProductNumbers.value.includes(productNumber));
    };

    const getAvailabilities = async (
        productNumbers: string[],
    ): Promise<Record<string, Schemas['AhProductAvailability'][]> | undefined> => {
        try {
            if (!isLoggedIn.value) return;

            const cleanedProductNumbers = _cleanProductNumbers(productNumbers);
            if (!cleanedProductNumbers?.length) return;

            const response = await apiClient.invoke('productAvailabilitySearch post /product-availability/search', {
                productNumbers: cleanedProductNumbers,
            });

            availabilities.value = ObjectHelper.merge(availabilities.value, response.availabilities);

            return availabilities.value;
        } catch (err) {
            if (err instanceof ApiClientError && err.status === 403) return;

            Logger.captureException(err);
        }
    };

    return {
        getAvailabilities,
        availabilities,
    };
};
